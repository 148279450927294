.App {
	text-align: left;
	background-color: #E9E9E9;
	padding: 0px;
	margin: 0px;
}

.section {
	background-color: #E9E9E9;
	padding: 2vw;
	overflow: hidden;
}

.fullsize {
	min-height: 90vh;
}

.content {
	background-color: white;
	padding: 2vw;
	padding-left: 2.5vw;
	overflow: hidden;
}

.line {
	flex: 1;
	border-bottom: 0.5vh solid #99D3DF;
	border-bottom-width: 0.5vh;
	content: '';
	width: 100%;
}

.spacing {
	padding: 1.5vw;
}

.nopadding{
	padding: 0px !important; 
}

.indent{
	padding-left: 1vw !important;
}

.linebreak{
	padding-bottom: 1.5vw;
}

h5 a.underline, a.underline:hover, a:link, a:active, a:focus {
	text-decoration: none !important;
	color: inherit !important;
} 

