 .navbar{
	background-color: white;
	color: #24305E;
	font-size: 1.5rem;
	font-family: open-sans, sans-serif;
	font-weight: 300;
	font-style: normal;
    position: relative;
    align-items:baseline;
    
} 

.nav-item{
	color: #24305E;
	font-size: 1.5rem;
	font-family: open-sans, sans-serif;
	font-weight: 300;
	font-style: normal;
    position: relative;
    align-items:baseline;
    width: fit-content;
    padding-right: 2vw;
    
}

.navbar-brand{
    background-color: white;
    color: #88BBD6 !important;
    font-size: 3.4rem;
    font-weight: 500;
    position: relative;
    align-items: baseline;
    padding-right: 1vw;
}


.nav-item:hover{
    color: #88BBD6;
}

.navbar-brand:hover{
    color: #88BBD6;
}

.navbar-brand:active{
    color: #88BBD6 !important; 
}

.navbar-brand:focus{
    color: #88BBD6 !important;
}

.header-background{
    background-color: white;
    background: white;
}

.switch {
    position: relative;
    align-content: center;
    margin: 0 5px;
    justify-content: end;
  }
  
  .switch > span {
    position: absolute;
    top: 10px;
    pointer-events: none;
    font-family: 'Rallway', Arial, sans-serif;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .06);
    width: 50%;
    text-align: center;
    
  }
  
  input.check-toggle-round-flat:checked ~ .off {
    color: #88BBD6;
  }
  
  input.check-toggle-round-flat:checked ~ .on {
    color: #fff;
  }
  
  .switch > span.on {
    left: 0;
    padding-left: 4px;
    color: #88BBD6;
  }
  
  .switch > span.off {
    right: 0;
    padding-right: 4px;
    color: #fff;
  }
  
  .check-toggle {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
  }
  .check-toggle + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  input.check-toggle-round-flat + label {
    padding: 2px;
    width: 97px;
    height: 35px;
    background-color: #88BBD6;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
    border-radius: 60px;
  }
  input.check-toggle-round-flat + label:before, input.check-toggle-round-flat + label:after {
    display: block;
    position: absolute;
    content: "";
  }
  
  input.check-toggle-round-flat + label:before {
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    background-color: #88BBD6;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
    border-radius: 60px;
  }

  input.check-toggle-round-flat + label:after {
    top: 4px;
    left: 4px;
    bottom: 4px;
    width: 45px;
    background-color: #fff;
    -webkit-border-radius: 52px;
    -moz-border-radius: 52px;
    -ms-border-radius: 52px;
    -o-border-radius: 52px;
    border-radius: 52px;
    -webkit-transition: margin 0.2s;
    -moz-transition: margin 0.2s;
    -o-transition: margin 0.2s;
    transition: margin 0.2s;
  }
  
  input.check-toggle-round-flat:checked + label {
  }
  
  input.check-toggle-round-flat:checked + label:after {
    margin-left: 44px;
  }

  @media only screen and (max-width: 600px) {
    .navbar-brand {
      font-size: 1.5rem;
    }

    .nav-item {
      font-size: 1rem;
    }
  }

  
 @media only screen and (min-width: 600px) and (max-width: 992px) {
    .navbar-brand {
      font-size: 2rem;
    }

    .nav-item {
      font-size: 1.2rem;
    }
  }