h1{
    font-size: 3.5rem;
    color: #88BBD6;
    font-family: 'Cabin', sans-serif;
    padding-bottom: 2vh;
    margin-bottom: 2.5vh;
    font-weight: 500;
}

h2{
    color: #24305E;
    font-size: 1.7rem;
    font-family: 'Raleway', sans-serif;
    font-weight: 200;
    padding-bottom: 1.5vh;
}

h3{
    color: #CDCDCD;
    font-size: 3rem;
    font-family: 'Cabin', sans-serif;
}

h4{
    color: #24305E;
    font-size: 1.7rem;
    font-family: 'Raleway', sans-serif;    
    font-weight: 200;
}

h5{
    color: #68A0BD;
    font-size: 1.7rem;
    font-family: 'Raleway', sans-serif;  
    font-weight: 200;
      
}

h5 a{
    text-decoration: none;
    position: relative;
    display: inline-flex;
}


h5 a.underline:after{
    content: '';
    position: absolute;
    left: 0;
    display: inline-block;
    height: 1em;
    width: 100%;
    border-bottom: 1px solid;
    margin-top: 10px;
    opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: scale(0,1);
	transform: scale(0,1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

h5 a.underline:hover:after{
    opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
} 

.bold{
    font-weight: bold;
}

/**Quote Font**/

h6 {
    color: #24305E;
    font-size: 1.4rem;
    font-family: 'Raleway', sans-serif;    
    font-style: italic;
    line-height: 1.5;
}

/**Quote Signature Font**/

h7 {
    color: #24305E;
    font-size: 1.4rem;
    font-family: 'Raleway', sans-serif;    
    line-height: 1.5;
    padding-left: 15vh;
}


@media only screen and (max-width: 600px) {
	h1 {
		font-size: 2rem
	}
	h2 {
		font-size: 1.1rem
	}
	h3 {
		font-size: 1.7rem
    }
    h4 {
        font-size: 1.2rem;
    }
    h5 {
        font-size: 1.2rem;
    }

}


@media only screen and (min-width: 600px) and (max-width: 992px) {
    h1 {
        font-size: 2.5rem;
    }
    h2 {
        font-size: 1.3rem;
    }
    h3 {
		font-size: 1.9rem
    }
    h4 {
        font-size: 1.2rem;
    }
    h5 {
        font-size: 1.2rem;
    }

}
