.playground{

  width: 100%;
  
  padding: 0px;
  margin: 0px;
  margin-left: 0px;
  padding-left: 0px;
  overflow: hidden;

}

.locations{
  height: 40vh;
  width: 100%;
  object-fit: cover;
  border-radius: 20px !important;
}

.carousel{  
  margin-left: 2vw;
  width: auto;
}

.carousel-item .img-fluid{
  width: fit-content;
  height: fit-content;
  object-fit: cover !important;
}